<template>
  <div class="home">
    <div class="container">
      <div class="main">
        <div class="sidebar">
          <h2 class="section-title">{{ $t('project.PortfolioOverview') }}</h2>
          <div class="card-list" v-if="projectList.length > 0">
            <div class="card" v-for="item in projectList" :key="item.name" @click="toDetail(item)">
              <div class="card-header">{{ getText(item, 'name') }}</div>
              <div class="card-content" v-if="item.id != $route.params.id">
                <el-row type="flex" justify="space-between">
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.DeployedCapital') }}：</span>
                      <span class="card-value">{{ item.totalInvest }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.ClosingDate') }}：</span>
                      <span class="card-value">{{ item.establish }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="section">
            <div class="section-header">
              <h2>{{ $t('nav.events') }}</h2>
              <router-link to="/events" class="more">{{ $t('more.events') }}<i class="el-icon-arrow-right"></i></router-link>
            </div>
            <div class="section-content">
              <div class="events-banner" v-if="topActivity">
                <div class="banner-pic" :style="`background-image: url('${topActivity.coverImage}')`"></div>
                <div class="banner-content">
                  <div class="title">{{ getText(topActivity, 'title') }}</div>
                  <Tag :status="topActivity.status" />
                  <div class="time">
                    <b>{{ $t('events.StartTime') }}：</b>{{ $t('BeijingTime') }} {{ topActivity.startTime }}
                  </div>
                  <div class="desc">
                    <b>{{ $t('events.Topic') }}：</b>{{ getText(topActivity, 'meetingTheme') }}
                  </div>
                  <EventButton :event="topActivity" />
                </div>
              </div>
            </div>
          </div>

          <div class="section" style="margin-top: 20px">
            <div class="section-header">
              <h2>{{ $t('latestInsights') }}</h2>
            </div>
            <div class="section-content">
              <div class="news-list insight-list" v-if="newsList.length > 0">
                <div class="item" v-for="item in newsList" :key="item.id">
                  <div class="item-pic" @click="toNewsDetail(item)"><el-image style="width: 168px; height: 110px" :src="item.coverImage" fit="cover"></el-image></div>
                  <div class="item-content">
                    <div class="title" @click="toNewsDetail(item)">{{ getText(item, 'title') }}</div>
                    <el-row type="flex" justify="space-between">
                      <div><div class="category-list" v-if="item.articleType"><div class="category">{{ item.articleType == 'INVEST_INSIGHT' ? $t('nav.insights') : $t('nav.consumerInsights') }}</div></div></div>
                      <div>
                        <a v-if="item.showDownload && item.docUrl" :href="item.docUrl" target="_blank" download class="el-button el-button--primary el-button--mini">{{ $t('fileDownload') }}</a>
                        <el-button type="primary" size="mini" @click="toPdf(item.docUrl)" v-if="item.showView && item.docUrl">{{ $t('ViewReport') }}</el-button>
                      </div>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="more-btns">
            <router-link to="/insights" class="more">{{ $t('more.insights') }}</router-link>
            <router-link to="/consumer" class="more">{{ $t('more.consumerInsights') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Tag from '@/components/Tag'
import EventButton from '@/components/EventButton'

export default {
  components: {
    Tag,
    EventButton,
  },
  data() {
    return {
      projectList: [],
      topActivity: null,
      newsList: [],
    }
  },
  watch: {
    $route() {
      this.purchasedFundAndProjectList()
      this.getTopActivity()
      this.getNewsList()
    },
  },
  created() {
    this.purchasedFundAndProjectList()
    this.getTopActivity()
    this.getNewsList()
  },
  methods: {
    purchasedFundAndProjectList() {
      api.purchasedFundAndProjectList().then((res) => {
        this.projectList = res.data
      })
    },
    toDetail(obj) {
      if (obj.special) {
        this.$router.push(`/project/${obj.id}`)
      } else {
        this.$router.push(`/fund/${obj.id}`)
      }
    },
    getTopActivity() {
      api.topActivity().then((res) => {
        this.topActivity = res.data
      })
    },
    getNewsList() {
      api
        .getArticles(
          {
            articleTypes: ['INVEST_INSIGHT', 'CONSUMER_RESEARCH'],
            recommend: true,
          },
          `size=10&page=0`
        )
        .then((res) => {
          this.newsList = res.data.content
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.events-banner .banner-content {
  width: 336px;
  padding: 20px;
}
</style>
